import React, { useState, useRef, useEffect } from 'react';
import { Grid, Card } from '@material-ui/core';
import AppNaviBar from './AppNaviBar';

const ImageViewer = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [imageState, setImageState] = useState({ images: [], lastImageId: 0 });

  const loaderRef = useRef(null);

  const fetchImages = async () => {
    setIsLoading(true);

    try {
      // 마지막 이미지 ID 계산, images 배열이 비었다면 빈 문자열('')
      console.log('lastImageId = ', imageState.lastImageId);
   
      const response = await fetch(`/get-images-${props.suffix}${imageState.lastImageId > 0 ? `?lastId=${imageState.lastImageId}` : ''}`);
      const data = await response.json();

      console.log('update data.lastMessageId = ', data.lastMessageId)
      setImageState(prev => ({
        ...prev, 
        images: [...prev.images, ...data.imageUrls], // 이미지 배열 업데이트
        lastImageId: data.lastMessageId // 마지막 image id 업데이트
      }));

    } catch (error) {
      console.error('Error fetching images:', error);
    }    

    setIsLoading(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !isLoading) {
        fetchImages();
      }
    }, {});

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [isLoading]);

  useEffect(() => {
    setImageState({ images: [], lastImageId: 0 });
  }, [props.suffix]);

  useEffect(() => {
    // lastImageId가 0으로 설정된 후 fetchImages 호출
    if (imageState.lastImageId === 0) {
      fetchImages();
    }
  }, [imageState.lastImageId]);

  return (
    <div style={{ marginTop: '85px' }}>
      <AppNaviBar title={props.title}/>
      <Grid container spacing={3}>
        {imageState.images.map((image, index) => (
         // <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <Card>
              <img src={image} alt={`image-${index}`} loading="lazy" style={{ width: '100%', height: 'auto' }} />
            </Card>
        //  </Grid>
        ))}
      </Grid>
      <div ref={loaderRef} style={{ height: "50px", margin: "30px 0" }}>
        {isLoading && "Loading..."}
      </div>
    </div>
  );
};

export default ImageViewer;
