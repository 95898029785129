import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// 테마 생성
const theme = createTheme({
  // 여기에 테마 관련 설정을 추가할 수 있습니다.
});

export default function AppNaviBar(props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <AppBar variant="outlined" position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">{props.title}</Typography>

          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <List>
            
              <ListItem button onClick={() => navigate('/genshin')}>
                <ListItemText primary="원신" />
              </ListItem>
              
              <ListItem button onClick={() => navigate('/bluearchive')}>
                <ListItemText primary="블루아카이브" />
              </ListItem>            

              <ListItem button onClick={() => navigate('/nikke')}>
                <ListItemText primary="니케" />
              </ListItem>  

              <ListItem button onClick={() => navigate('/arknights')}>
                <ListItemText primary="명일방주" />
              </ListItem>  

              <ListItem button onClick={() => navigate('/umamusume')}>
                <ListItemText primary="우마무스메" />
              </ListItem> 

              <ListItem button onClick={() => navigate('/honkai')}>
                <ListItemText primary="붕괴" />
              </ListItem> 

              <ListItem button onClick={() => navigate('/etc')}>
                <ListItemText primary="아무거나" />
              </ListItem> 

            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
