import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ImageViewer from './ImageViewer'; // 기존의 ImageViewer 컴포넌트
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const theme = createTheme({

 });

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<ImageViewer suffix="genshin" title="원신"/>} />
            <Route path="/genshin" element={<ImageViewer suffix="genshin" title="원신"/>} />
            <Route path="/nikke" element={<ImageViewer suffix="nikke" title="니케"/>} />
            <Route path="/bluearchive" element={<ImageViewer suffix="bluearchive" title="블루아카이브"/>} />
            <Route path="/arknights" element={<ImageViewer suffix="arknights" title="명일방주"/>} />
            <Route path="/umamusume" element={<ImageViewer suffix="umamusume" title="우마무스메"/>} />
            <Route path="/honkai" element={<ImageViewer suffix="honkai" title="붕괴"/>} />
            <Route path="/etc" element={<ImageViewer suffix="etc" title="아무거나"/>} />
          </Routes>
        </Router>
    </ThemeProvider>
  );
}

export default App;